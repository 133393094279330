/**
 * https://github.com/RobinHerbots/Inputmask#usage
 */

import Inputmask from 'inputmask/lib/inputmask';

const masks = {
  phoneRu: { mask: '+7 (999) 999 9999' },
  phone: { regex: '[\\d\\s-+()]*' },
  numbers: { regex: '\\d*' },
  date: { mask: '99.99.9999' },
  period: { mask: '99.99.9999-99.99.9999' },
  time: { mask: '99:99' },
  fullTime: { mask: '99:99:99' }
};

function setMask(element, valueAccessor) {
  let pattern = ko.toJS(valueAccessor());

  if (!pattern) return;

  if (pattern in masks) pattern = masks[pattern];

  if (pattern && typeof pattern === 'object') {
    pattern.showMaskOnHover = false;
    pattern.showMaskOnFocus = false;
  }

  new Inputmask(pattern, {
    placeholder: '',
    showMaskOnFocus: false,
    showMaskOnHover: false
  }).mask(element);

  ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
    Inputmask.remove(element);
  });
}

const maskBinding = {
  init: setMask,
  update: setMask
};

import { registerBinding } from 'Utils/engine/register-binding';
registerBinding('fbMask', maskBinding);
