export function getComponent(componentName, componentUrl) {
  if (ko.components.isRegistered(componentName)) {
    return Promise.resolve();
  }
  return import(`${componentUrl}`).then((module) => {
    console.info(module);
  });
}

window.getComponent = getComponent;
