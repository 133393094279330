import { registerBinding } from "Utils/engine/register-binding";

const defaultConfig = {
  duration: 400,
  delay: 0,
};

const handler = (
  element,
  valueAccessor,
  allBindings,
  viewModel,
  bindingContext
) => {
  let isVisible = valueAccessor();

  let userConfig = allBindings.get("config") || {};
  const config = {
    ...defaultConfig,
    ...userConfig,
  };

  let $element = $(element);

  if (ko.toJS(isVisible)) {
    $element.slideDown(config.duration, () => {
      if (typeof config.onAnimationEnd === "function")
        config.onAnimationEnd(true);
    });
  } else {
    $element.slideUp(config.duration, () => {
      if (typeof config.onAnimationEnd === "function")
        config.onAnimationEnd(false);
    });
  }
};

const binding = {
  init: handler,
  update: handler,
};

registerBinding("fbSlide", binding);
