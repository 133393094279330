var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Sortable from "sortablejs";
import "./style.less";
var initHandler = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    var config = valueAccessor();
    var _a = config || {}, foreach = _a.foreach, options = _a.options;
    var _b = options || {}, onAdd = _b.onAdd, onUpdate = _b.onUpdate, onRemove = _b.onRemove, onChange = _b.onChange, onEnd = _b.onEnd, onSort = _b.onSort, beforeMoved = _b.beforeMoved, comparer = _b.comparer, fields = __rest(_b, ["onAdd", "onUpdate", "onRemove", "onChange", "onEnd", "onSort", "beforeMoved", "comparer"]);
    element.__fb_sortable_value = foreach;
    function getList(el) {
        var container = el;
        return container.__fb_sortable_value;
    }
    var params = __assign({ forceFallback: true, fallbackClass: "fb-sortable-clone", ghostClass: "fb-sortable-ghost", chosenClass: "fb-sortable-chosen", dragClass: "fb-sortable-drag", onClone: function (event) {
            event.clone.style.height = "";
        }, onAdd: function (event) {
            // добавление в текущий список
            if (ko.isWriteableObservable(foreach)) {
                var newIndex = event.newIndex, oldIndex = event.oldIndex, from = event.from, item = event.item;
                var sourceValue = getList(from);
                if (ko.isObservable(sourceValue)) {
                    var movedElement = ko.unwrap(sourceValue)[oldIndex];
                    var oldValue = foreach();
                    var newValue = __spreadArray([], oldValue, true);
                    newValue.splice(newIndex, 0, movedElement);
                    if (typeof comparer === "function") {
                        newValue.sort(comparer);
                        foreach([]); // TODO: hack, иначе не обновляется UI
                    }
                    item.remove();
                    foreach(newValue);
                }
            }
            setTimeout(function () {
                if (typeof onAdd === "function")
                    onAdd(event);
            });
        }, onRemove: function (event) {
            // удаление из текущего списка
            if (ko.isWriteableObservable(foreach)) {
                var oldIndex = event.oldIndex;
                var oldValue = foreach();
                var newValue = __spreadArray([], oldValue, true);
                newValue.splice(oldIndex, 1);
                foreach(newValue);
            }
            if (typeof onRemove === "function")
                setTimeout(function () { return onRemove(event); });
        }, onUpdate: function (event) {
            // перемещение в пределах текущего списка
            if (ko.isWriteableObservable(foreach)) {
                var oldIndex = event.oldIndex, newIndex = event.newIndex;
                var oldValue = foreach();
                var newValue = __spreadArray([], oldValue, true);
                var movedElement = newValue[oldIndex];
                newValue.splice(oldIndex, 1);
                newValue.splice(newIndex, 0, movedElement);
                console.log({ newValue: newValue, comparer: comparer });
                if (typeof comparer === "function") {
                    newValue.sort(comparer);
                    foreach([]); // TODO: hack, иначе не обновляется UI
                }
                foreach(newValue);
            }
            if (typeof onUpdate === "function")
                setTimeout(function () { return onUpdate(event); });
        }, onEnd: function (event) {
            setTimeout(function () {
                if (typeof onEnd === "function") {
                    onEnd(event);
                }
            });
        }, onSort: function (event) {
            // элемент перемещен
            console.log("on sort", onEnd);
            if (typeof onSort === "function")
                setTimeout(function () { return onSort(event); });
        }, onChange: function (event) {
            // элемент в процессе перемещения, меняет положение
            if (typeof onChange === "function")
                onChange(event);
        }, onMove: function (event, originalEvent) {
            // вызывается постоянно в процессе перемещения, можно отменить
            if (typeof beforeMoved === "function") {
                var res = beforeMoved(event, originalEvent);
                if (res) {
                    var enabled = res.enabled, message = res.message;
                    if (enabled) {
                        event.dragged.classList.remove("fb-sortable-disabled");
                        event.dragged.dataset.sortableMessage = "";
                    }
                    else {
                        event.dragged.classList.add("fb-sortable-disabled");
                        if (message)
                            event.dragged.dataset.sortableMessage = message;
                    }
                    return enabled;
                }
            }
        } }, fields);
    console.log({ options: options, onEnd: onEnd, params: params });
    var model = Sortable.create(element, params);
    element.__fb_sortable = model;
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        model.destroy();
    });
    return ko.bindingHandlers.template.init(element, valueAccessor, allBindings, viewModel, bindingContext);
};
var updateHandler = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    return ko.bindingHandlers.template.update(element, valueAccessor, allBindings, viewModel, bindingContext);
};
var binding = {
    init: initHandler,
    update: updateHandler,
};
export default binding;
