

import { registerBinding } from 'Utils/engine/register-binding';
registerBinding('fbTimeStart', {
  init: (el, valueAccessor) => {
    console.time(valueAccessor());
  }
});
registerBinding('fbTimeEnd', {
  init: (el, valueAccessor) => {
    console.timeEnd(valueAccessor());
  }
});
