ko.bindingHandlers.modalContainerModalWrapper = {
	init: function init(
		element,
		valueAccessor,
		allBindings,
		viewModel,
		bindingContext
	) {
		var options = $.extend(
			{
				closeOnBackdropClick: true,
				escapePress: 'close',
			},
			allBindings()['modalContainerModalWrapperOptions']
		);

		var close = allBindings()['modalContainerModalWrapperClose'];

		var $element = $(element);
		var $modal = $element.find('.modal');

		$modal.appendTo('body');

		$modal.modal({
			backdrop: false,
			keyboard: false,
			focus: true,
		});

		if ('modalClass' in options) {
			$modal.addClass(options.modalClass);
		}

		$modal.keyup(function (event) {
			if (event.keyCode === 27) {
				if (options.escapePress === 'close') {
					close.call(viewModel);
				} else if (options.escapePress instanceof Function) {
					options.escapePress();
				}
			}
		});

		if (options.closeOnBackdropClick) {
			$modal.on('click', function (event) {
				if (event.target === $modal.get()[0]) {
					close.call(viewModel);
				}
			});
		}

		var innerBindingContext = ko.bindingEvent.startPossiblyAsyncContentBinding(
			$modal.get()[0],
			bindingContext
		);

		ko.applyBindings(innerBindingContext, $modal.get()[0]);

		ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
			$modal.modal('hide').remove();
		});
	},
};

ko.components.register('modal-container', {
	viewModel: {
		createViewModel: function createViewModel(params) {
			var viewModel = new (function () {
				this.opens = params.opens;

				this.close = function (open, value) {
					this.opens.remove(open);

					if ('close' in open) {
						open.close(value);
					}
				};
			})();

			viewModel.initializing = ko.observable(true);

			viewModel.onInit = function () {
				viewModel.initializing(false);
			};

			return viewModel;
		},
	},
	template:
		`<!-- ko template: { afterRender: onInit } -->

			<!-- ko foreach: opens -->

        <!-- ko let: { modalElement: ko.observable() } -->
				<div data-bind="
					modalContainerModalWrapper,
					modalContainerModalWrapperOptions: $data.options,
					modalContainerModalWrapperClose: function (value) {
						$component.close($data, value);
					}">

					<div class="modal modal-with-base-backdrop"
						role="dialog" tabindex="1"
						data-bind="element: modalElement">

            <!-- ko template: {
							name: dialogTemplateName,
							data: {
								data: $data.data,
								modalElement: modalElement(),
								close: function (value) {
									$component.close($data, value); }
								}
							} -->
						<!-- /ko -->

					</div>

        </div>
				<!-- /ko -->

			<!-- /ko -->

    <!-- /ko -->`,
});
