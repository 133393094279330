const defaultConfig = {
  duration: 400,
  delay: 0,
};

const transitionManager = (element) => {
  if (element.transitionManager) return element.transitionManager;

  let _listener = null;

  return (duration, cb) => {
    if (_listener) {
      element.removeEventListener('transitionend', _listener);
    }
    _listener = () => {
      setTimeout(() => {
        if (typeof cb === 'function') {
          cb();
          cb = null;
        }
        element.style.transition = '';
      });
    };
    element.addEventListener('transitionend', _listener);

    element.style.transition = `opacity ${duration}ms linear`;
  };
};

const handler = function (element, valueAccessor, allBindings, viewModel, bindingContext) {
  let isVisible = valueAccessor();

  ko.applyBindingsToDescendants(bindingContext, element);


  let userConfig = allBindings.get('config') || {};
  const config = {
    ...defaultConfig,
    ...userConfig
  };

  element.transitionManager = transitionManager(element);

  if (ko.toJS(isVisible)) {
    element.style.display = '';

    setTimeout(() => {
      element.transitionManager(config.duration, () => {
        element.style.opacity = '';
      });

      element.style.opacity = 1;
    });
  } else {
    element.transitionManager(config.duration, () => {
      element.style.display = 'none';
    });
    element.style.opacity = 0;
  }

  return { controlsDescendantBindings: true };
};

const binding = {
  init: handler,
  update: handler
};

import { registerBinding } from 'Utils/engine/register-binding';
registerBinding('fbFade', binding);
