ko.bindingHandlers.stickyWatcher = {
  init: function (element, valueAccessor) {
    let params = valueAccessor() || {};

    const observer = new IntersectionObserver(
      ([e]) => {
        console.log({ e });
        let isSticky = e.intersectionRatio < 1;
        $(element).trigger(isSticky ? "sticky.stuck" : "sticky.unstuck");
        e.target.classList.toggle("isSticky", isSticky);
      },
      { threshold: [1], ...params }
    );

    const observer0 = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle("isHidden", e.isIntersecting);
      },
      { threshold: [0], ...params }
    );

    observer.observe(element);
    observer0.observe(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      observer.unobserve(element);
    });
  },
};

function slideBeforeRemoveFactory(duration = 200, delay = 0) {
  return (element) =>
    $(element)
      .delay(delay)
      .slideUp(duration, function () {
        $(element).remove();
        $(window).trigger("resize");
      });
}

function slideAfterAddFactory(duration = 200, delay = 0) {
  return (element) =>
    $(element)
      .hide()
      .delay(delay)
      .slideDown(duration, () => {
        $(window).trigger("resize");
      });
}

function fadeBeforeRemoveFactory(duration = 200, delay = 0, cb) {
  return (element) =>
    $(element)
      .delay(delay)
      .fadeOut(duration, function () {
        $(element).remove();
      });
}

function fadeAfterAddFactory(duration = 200, delay = 0, cb) {
  return (element) =>
    $(element)
      .hide()
      .delay(delay)
      .fadeIn(duration, function () {
        if (typeof cb === "function") cb();
      });
}

function templateIf(condition, data) {
  return condition ? [data] : undefined;
}

window.slideBeforeRemoveFactory = slideBeforeRemoveFactory;
window.slideAfterAddFactory = slideAfterAddFactory;
window.fadeBeforeRemoveFactory = fadeBeforeRemoveFactory;
window.fadeAfterAddFactory = fadeAfterAddFactory;
window.templateIf = templateIf;
