import "./fb-on-enter";
import "./fb-mask";
import NumberBinding from "./fb-number";
import "./fb-ref";

import "./fb-slide";
import "./fb-time";

import "./fb-fade";
import SortableBinding from "./fb-sortable/index";
import "./fb-sortable/_index.js";

import { registerBinding } from "Utils/engine/register-binding";
registerBinding("fbNumber", NumberBinding);
registerBinding("fbSortable", SortableBinding);
