ko.bindingHandlers.element = {
  init: function init(element, valueAccessor, allBindings) {
    if (ko.isObservableArray(valueAccessor())) {
      if (allBindings.has('elementIndex')) {
        valueAccessor().splice(allBindings.get('elementIndex'), 0, element);
      } else {
        valueAccessor().push(element);
      }
    } else {
      valueAccessor()(element);
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      if (ko.isObservableArray(valueAccessor())) {
        valueAccessor().remove(element);
      } else {
        valueAccessor()(null);
      }
    });
  },
};
